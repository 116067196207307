import { Trans, t } from "@lingui/macro";
import cx from "classnames";
import { useMedia } from "react-use";
import { Tooltip } from "components/TooltipV2/Tooltip";
import { HiQuestionMarkCircle } from "react-icons/hi";
import "./Challenge.scss";
import Button from "components/Button/Button";
import { Countdown } from "components/Countdown/Countdown";
import ProgressBar from "../../ChallengeProgress";
import SuccessTip from "img/success-tip.svg";
import ResetIcon from "img/reset.svg";
import { useClipboard } from "pages/Referrals/hooks/useClipboard";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import TraderContractAbi from "abis/Trader.json";
import { useChainId } from "lib/chains";
import { callContract } from "lib/contracts";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import { FundAccountModal } from "../../FundAccountModal";
import { NavLink } from "react-router-dom";
import { useFundedTrader } from "hooks/useFundedTrader";
import { USDC_DECIMALS } from "lib/constants";
import { formatBigAmount } from "lib/formatAmount";
import ChallengeFailed from "./ChallengeFailed";
import ChallengeMaintained from "./ChallengeMaintained";
import CashoutModal from "components/Funded/CashoutModal";
import RolloverModal from "components/Funded/RolloverModal";
import ChallengePromoted from "./ChallengePromoted";
import ChallengeDemoted from "./ChallengeDemoted";
import { ChallengeResults, FundedLevels } from "components/Funded/types";
import RestartModal from "components/Funded/RestartModal";
import Cashout from "./Cashout";
import { useDrawdown } from "hooks/useDrawdown";

export const ChallengeTab = ({ traderContractAddress, setPendingTxns, pendingTxns, isCreateFundModalOpen }) => {
  const [progress, setProgress] = useState(0);
  const [copiedUrl, setCopiedUrl] = useState(null);
  const [pnl, setPnl] = useState<string | null>("0");
  const [bestPnl, setBestPnl] = useState<string | null>("0");
  const [bestPnlValue, setBestPnlValue] = useState<string | null>("0");
  const [startingCapital, setStartingCapital] = useState<string>();
  const [traderChallenge, setTraderChallenge] = useState<number>(0);
  const [daysInChallenge, setDaysInChallenge] = useState<number | undefined>(0);
  const [activeDays, setActiveDays] = useState<number | undefined>(0);
  const [challengeEndTime, setChallengeEndTime] = useState<number | undefined>(Date.now());
  const [challengeStartedAtTimestamp, setChallengeStartedAtTimestamp] = useState<BigNumber>(BigNumber.from(0));
  const [isResetChallengeModalOpen, setIsResetChallengeModalOpen] = useState<boolean>(false);
  const [isFundAccountModalOpen, setIsFundAccountModalOpen] = useState(false);
  const [isChallengeResetting, setIsChallengeResetting] = useState(false);
  const [isLoadingChallengeTimestamp, setIsLoadingChallengeTimestamp] = useState(true);
  const [isCashoutModalOpen, setIsCashoutModalOpen] = useState(false);
  const [isRolloverModalOpen, setIsRolloverModalOpen] = useState(false);
  const [challengeResult, setChallengeResult] = useState<ChallengeResults | undefined>();
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [canResetChallenge, setCanResetChallenge] = useState<boolean>();
  const [tradesWon, setTradesWon] = useState<number>(0);

  const { provider } = useWeb3React();
  const { chainId } = useChainId();
  const {
    traderChallengeConfig,
    basePoints,
    traderContract,
    fundedBalance: balance,
    totalCashout,
    isLoading: isLoadingFundedTrader,
    resChallengeNumber,
    openPositionsCount,
    challengeInProgress,
    refetchChallengeStats,
  } = useFundedTrader();

  const maxDaysInChallenge = traderChallengeConfig ? traderChallengeConfig.challengeDurationInDays : 0;
  const expectedTradesCompleted = traderChallengeConfig ? traderChallengeConfig.minTradeCount : 0;
  const expectedActiveDays = traderChallengeConfig ? traderChallengeConfig.minActiveDays : 0;

  const startingCollateral =
    traderChallengeConfig && traderChallengeConfig.funding.gt(0)
      ? traderChallengeConfig.funding
      : traderChallengeConfig && traderChallengeConfig.minDepositStable
      ? traderChallengeConfig.minDepositStable
      : BigNumber.from(0);

  const expectedCollateral = useMemo(() => {
    if (traderChallengeConfig && startingCapital && basePoints.gt(0)) {
      return formatBigAmount(
        BigNumber.from(startingCapital).mul(traderChallengeConfig.pnlPromotionBp).div(basePoints).toString(),
        USDC_DECIMALS,
        "0",
        { mantissa: 0 }
      );
    }

    return "0";
  }, [startingCapital, traderChallengeConfig, basePoints]);


  const drawdownData = useDrawdown();

  const [currentFundedLevel, setCurrentFundedLevel] = useState<string | null>(null);
  useEffect(() => {
    const fundedLevels = {
      0: "Qualification",
      1: "Qualification",
      2: "Bronze",
      3: "Silver",
      4: "Gold",
      5: "Platinum",
    };
      if (resChallengeNumber) {
        const formattedLevelNumber = resChallengeNumber.toNumber() + 1;
        setCurrentFundedLevel(fundedLevels[formattedLevelNumber]);
      }
    }, [resChallengeNumber]);

  const expectedPnL =
    traderChallengeConfig && basePoints.gt(0)
      ? Math.round((traderChallengeConfig.pnlPromotionBp / basePoints.toNumber()) * 100 - 100)
      : 0;

  const pnlPercent = useMemo(() => {
    if (pnl && startingCapital && balance) {
      if (!BigNumber.from(pnl).eq(0)) {
        return Number(
          formatBigAmount(
            BigNumber.from(pnl)
              .mul(BigNumber.from(10).pow(USDC_DECIMALS))
              .div(BigNumber.from(startingCapital))
              .mul(100),
            USDC_DECIMALS
          )
        );
      }
    }

    return 0;
  }, [pnl, startingCapital, balance]);

  const overallProfitPerc: any = useMemo(() => {
    if (pnl && bestPnl) {
      if (BigNumber.from(pnl).lt(0) || BigNumber.from(bestPnl).lt(0) || BigNumber.from(pnl).isZero()) {
        return "N/A";
      } else {
        const best_pnl = (Number(bestPnl) / Math.pow(10, USDC_DECIMALS)).toFixed(2);
        const general_pnl = (Number(pnl) / Math.pow(10, USDC_DECIMALS)).toFixed(2);
        const result = (Number(best_pnl) / Number(general_pnl)) * 100;
        return result.toFixed(2);
      }
    }
  }, [pnl, bestPnl]);

  const getTraderContractData = useCallback(async () => {
    if (traderContract && provider && traderChallengeConfig) {
      setIsLoadingChallengeTimestamp(true);
      //address:0x4B8cA86eFF9416Ac36c862EBeEe9a60669659920
      //traderContractAddress
      const traderContract = new ethers.Contract(traderContractAddress, TraderContractAbi.abi, provider?.getSigner());

      try {
        const [challengeStartedAtTimestamp, pnl, startingCapital, tradesWon, bestPnl] = await Promise.all([
          traderContract.challengeStartedAtTimestamp(),
          traderContract.pnl(),
          traderContract.startingCapital(),
          traderContract.tradesWon(),
          traderContract.bestPnl(),
        ]);

        // We need to separte canResetChallenge call because for old accounts, it is being reverted

        try {
          const canReset = await traderContract.canResetChallenge();
          setCanResetChallenge(canReset);
        } catch {
          setCanResetChallenge(false);
        }

        setChallengeStartedAtTimestamp(challengeStartedAtTimestamp);

        if (!challengeInProgress && !openPositionsCount && challengeStartedAtTimestamp.gt(0)) {
          try {
            const result = await traderContract.evaluateTraderKpis([
              traderChallengeConfig.challengeDurationInDays,
              traderChallengeConfig.minActiveDays,
              traderChallengeConfig.traderProfitShareBp,
              traderChallengeConfig.poolProfitShareBp,
              traderChallengeConfig.minTradeCount,
              traderChallengeConfig.targetWinRatioBp,
              traderChallengeConfig.pnlDemotionBp,
              traderChallengeConfig.pnlPayoutBp,
              traderChallengeConfig.pnlPromotionBp,
              traderChallengeConfig.minPositionCollateralBp,
              traderChallengeConfig.maxPositionCollateralBp,
              traderChallengeConfig.minLeverageBp,
              traderChallengeConfig.maxLeverageBp,
              traderChallengeConfig.funding,
              traderChallengeConfig.minDepositStable,
              traderChallengeConfig.fundingNft,
            ]);
            setChallengeResult(result[0]);
          } catch (error) {
            if (openPositionsCount) {
              setIsErrorModalOpen(true);
            }
          }
        }

        if (challengeStartedAtTimestamp.gt(0)) {
          const currentTimestamp = Date.now();
          const startTime = challengeStartedAtTimestamp.toNumber() * 1000;
          const timeDifference = currentTimestamp - startTime;
          const daysPassed = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

          const endOfTheChallengeTimestamp = maxDaysInChallenge * 24 * 60 * 60;
          setChallengeEndTime(endOfTheChallengeTimestamp + challengeStartedAtTimestamp.toNumber());
          setDaysInChallenge(Math.min(daysPassed, maxDaysInChallenge));
          try {
            const activeDays = await traderContract.getActiveDaysTradesCount(daysPassed);
            setActiveDays(activeDays.activeDays);
            setTraderChallenge(activeDays.trades);
          } catch (error) {
            setDaysInChallenge(0);
            setActiveDays(0);
            setChallengeEndTime(0);
            setIsLoadingChallengeTimestamp(false);
          }
        } else {
          setDaysInChallenge(0);
          setActiveDays(0);
          setChallengeEndTime(0);
        }

        setTradesWon(tradesWon);
        setStartingCapital(startingCapital);
        setPnl(pnl);
        let best_pnl = (Number(bestPnl) / Math.pow(10, USDC_DECIMALS)).toFixed(2);
        setBestPnlValue(best_pnl);
        setBestPnl(bestPnl);
        setIsLoadingChallengeTimestamp(false);
      } catch (error) {}
    }
  }, [
    maxDaysInChallenge,
    provider,
    traderContract,
    traderContractAddress,
    traderChallengeConfig,
    openPositionsCount,
    challengeInProgress,
  ]);

  useEffect(() => {
    getTraderContractData();
  }, [getTraderContractData]);

  const calculateProgressNumber = (currentValue, maxValue) => {
    if (maxValue) {
      const progress = Math.floor((currentValue * 100) / maxValue);
      return progress > 100 ? 100 : progress;
    } else {
      return 0;
    }
  };

  const tradesWinRate = useMemo(() => {
    return traderChallenge > 0 ? (tradesWon / traderChallenge) * 100 : 0;
  }, [tradesWon, traderChallenge]);

  const targetWinRate = useMemo(() => {
    return traderChallengeConfig?.targetWinRatioBp
      ? (traderChallengeConfig.targetWinRatioBp / basePoints.toNumber()) * 100
      : 0;
  }, [basePoints, traderChallengeConfig]);

  const daysInChallengeProgressNumber = calculateProgressNumber(daysInChallenge, maxDaysInChallenge);
  const tradesCompletedProgressNumber = calculateProgressNumber(traderChallenge, expectedTradesCompleted);
  const activeDaysProgressNumber = calculateProgressNumber(activeDays, expectedActiveDays);
  // Add 100 to avoid minus value calculation
  const pnlProgressNumber = calculateProgressNumber(pnlPercent !== 0 ? pnlPercent + 100 : 0, expectedPnL + 100);

  // winrate progress calculation
  const winRateProgressNumber = calculateProgressNumber(tradesWinRate, targetWinRate);

  const isMinCollateralEnough = balance && expectedCollateral ? balance.gt(startingCollateral) : false;

  const achievementsData = [
    // { trophy: "Qualified", points: 500, quantity: 4, totalPoints: 2000, url: "Qualified", twitterLink: "" },
    // { trophy: "Promoted", points: 500, quantity: 4, totalPoints: 2000, url: "Promoted", twitterLink: "" },
    // { trophy: "Retained", points: 500, quantity: 4, totalPoints: 2000, url: "Retained", twitterLink: "" },
    // { trophy: "Days Funded", points: 500, quantity: 4, totalPoints: 2000, url: "Days Funded", twitterLink: "" },
  ];
  const clipboard = useClipboard();

  const isMobile = useMedia("(max-width: 1023px)");
  const isMobileNarrower = useMedia("(max-width: 900px)");

  const percentages = useMemo(
    () => [
      daysInChallengeProgressNumber,
      tradesCompletedProgressNumber,
      pnlProgressNumber,
      activeDaysProgressNumber,
      winRateProgressNumber,
    ],
    [
      activeDaysProgressNumber,
      daysInChallengeProgressNumber,
      pnlProgressNumber,
      tradesCompletedProgressNumber,
      winRateProgressNumber,
    ]
  );

  const handleCopy = (url) => {
    clipboard.copy(url);
    setCopiedUrl(url);
    setTimeout(() => setCopiedUrl(null), 2000); // Clear the state after 2 seconds
  };

  const handleChallengeReset = async () => {
    if (traderContract) {
      setIsChallengeResetting(true);
      try {
        await callContract(chainId, traderContract, "reset", [], {
          sentMsg: t`Reset challenge submitted.`,
          failMsg: t`Reset challenge failed.`,
          successMsg: t`The challenge reset successfully.`,
          setPendingTxns,
          txnSuccessCallback: () => {
            setIsChallengeResetting(false);
            setIsResetChallengeModalOpen(false);
          },
        });
      } catch (error) {
        setIsChallengeResetting(false);
        setIsResetChallengeModalOpen(false);
      }
    }
  };

  useEffect(() => {
    const total = percentages.reduce((acc, curr) => acc + curr, 0);
    setProgress(() => (percentages.length > 0 ? Math.floor(total / percentages.length) : 0));
  }, [daysInChallengeProgressNumber, percentages]);

  const isFetchingData = useMemo(
    () => isLoadingFundedTrader || isLoadingChallengeTimestamp,
    [isLoadingFundedTrader, isLoadingChallengeTimestamp]
  );

  const handleRestartQualification = async () => {
    if (traderContract) {
      await callContract(chainId, traderContract, "withdraw", [BigNumber.from(0)], {
        sentMsg: t`Restarting qualification submitted.`,
        failMsg: t`Restarting qualification failed.`,
        setPendingTxns,
        txnSuccessCallback: () => {
          setIsFundAccountModalOpen(true);
        },
      });
    }
  };

  const handleRestartSameChallenge = async () => {
    if (resChallengeNumber && resChallengeNumber.toNumber() === 0) {
      handleRestartQualification();
      return;
    }

    setIsRestartModalOpen(true);
  };

  const handleRenderChallengeActionCard = () => {
    // Challenge already started, resChallengeNumber required

    switch (challengeResult) {
      case ChallengeResults.MAINTAIN:
        // If qualification maintained, it means failed
        if (resChallengeNumber && resChallengeNumber.toNumber() === 0) {
          return (
            <ChallengeFailed
              cashout={totalCashout}
              isLoading={isFetchingData}
              challengeRank={resChallengeNumber?.toNumber() || 0}
              onCashout={() => setIsCashoutModalOpen(true)}
              onRestartQualification={handleRestartQualification}
            />
          );
        }

        return (
          <ChallengeMaintained
            cashout={totalCashout}
            isLoading={isFetchingData}
            challengeRank={resChallengeNumber?.toNumber() || 0}
            onRestart={handleRestartSameChallenge}
            onCashout={() => setIsCashoutModalOpen(true)}
          />
        );
      case ChallengeResults.PROMOTION:
        return (
          <ChallengePromoted
            cashout={totalCashout}
            isLoading={isFetchingData}
            challengeNumber={Math.min(resChallengeNumber!.toNumber() + 1, FundedLevels.length - 1)}
            onRollover={() => setIsRolloverModalOpen(true)}
            onCashout={() => setIsCashoutModalOpen(true)}
          />
        );
      case ChallengeResults.DEMOTION:
        return (
          <ChallengeDemoted
            cashout={totalCashout}
            isLoading={isFetchingData}
            challengeNumber={(resChallengeNumber?.toNumber() ?? 0) + 1}
            fundedLevel={FundedLevels[(resChallengeNumber?.toNumber() ?? 0) + 1]}
            onCashout={() => setIsCashoutModalOpen(true)}
            onRestart={handleRestartSameChallenge}
          />
        );
      case ChallengeResults.RESET:
        return (
          <ChallengeFailed
            cashout={totalCashout}
            isLoading={isFetchingData}
            challengeRank={resChallengeNumber?.toNumber() || 0}
            onCashout={() => setIsCashoutModalOpen(true)}
            onRestartQualification={handleRestartQualification}
          />
        );
      default:
        return (
          <>
            <div className="flex flex-row lg:flex-col gap-[1rem] lg:gap-[0.5rem]">
              <Cashout cashout={totalCashout} isLoading={isFetchingData} />
              <div className="Challenge-timer-subblock">
                <Tooltip
                  renderContent={() => {
                    return (
                      <div className="Stake-card-infobox-tooltip">
                        <Trans>Points</Trans>
                      </div>
                    );
                  }}
                >
                  <div className="Tooltip-handle-wrapper">
                    <div className="inactive-text">
                      <Trans>Points</Trans>
                    </div>
                    <HiQuestionMarkCircle className="inactive-text" />
                  </div>
                </Tooltip>
                <p className="text-white text-[2rem] mt-[0.4rem]">0</p>
              </div>
            </div>
            <div>
              {!isLoadingChallengeTimestamp && (
                <Countdown countdownEndTimestamp={(challengeEndTime as number) * 1000} isFunded />
              )}
              {isMinCollateralEnough || !challengeStartedAtTimestamp.isZero() ? (
                <NavLink
                  to={"/"}
                  className="inline-block text-center !text-black px-[2rem] py-[1rem] rounded-[1.5rem] w-full mt-[1rem] Create-funded-btn !border-none"
                >
                  <Trans>Trade</Trans>
                </NavLink>
              ) : (
                <Button
                  variant="primary-action"
                  onClick={() => setIsFundAccountModalOpen(true)}
                  className="w-full rounded mt-1 !text-black"
                >
                  Fund
                </Button>
              )}

              {canResetChallenge && (
                <button
                  className="text-warning-yellow mt-[1rem] flex items-center gap-[0.5rem] mx-auto text-[1.2rem]"
                  onClick={() => setIsResetChallengeModalOpen(true)}
                >
                  <img src={ResetIcon} alt="Reset challenge" width="16" height="16" />
                  <Trans>Reset Challenge</Trans>
                </button>
              )}
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="Tab-content flex-column">
        <div className="Challenge-grid">
          <div className="flex justify-between items-center">
            <div className="Challenge-progress Common-card px-[1.5rem] py-[2rem] w-[50%]">
              <div className="flex">
                <div>
                  <p className="Common-subtitle flex gap-x-10">
                    <Trans>Challenge Progress</Trans>
                    <p className={`text-[2.4rem] ${progress > 50 ? "text-red" : "text-green"}`}>
                      {isFetchingData ? "-" : progress}%
                    </p>
                  </p>
                  {challengeInProgress && progress > 0 && pnlPercent > 0 && (
                    <p className="text-inactive">
                      <Trans>Nice work. you're currently on track for funding!</Trans>
                    </p>
                  )}
                </div>
              </div>
              <ProgressBar progress={progress} />
            </div>

            <div className="w-[272px]">
              <BaseChallengeStatsCard
                tooltipText="Largest win, cannot be 50% or more of your overall profit"
                title="Maxprofit $/% Overall profit"
                specialDataFormat={
                  <>
                    <span
                      className={
                        isNaN(Number(bestPnlValue) / Number(overallProfitPerc)) ||
                        Number(bestPnlValue) / Number(overallProfitPerc) < 50
                          ? "text-green"
                          : "text-red"
                      }
                    >
                      ${bestPnlValue}
                    </span>{" "}
                    /{" "}
                    <span
                      className={
                        isNaN(Number(bestPnlValue) / Number(overallProfitPerc)) ||
                        Number(bestPnlValue) / Number(overallProfitPerc) < 50
                          ? "text-green"
                          : "text-red"
                      }
                    >
                      {overallProfitPerc}%
                    </span>
                  </>
                }
                loading={isFetchingData}
              />
            </div>
          </div>
          <ul className="Challenge-stats">
            <BaseChallengeStatsCard
              tooltipText="Days complete/Overall"
              title="Days In Challenge"
              progress={daysInChallengeProgressNumber}
              maxValue={maxDaysInChallenge}
              currentValue={daysInChallenge}
              loading={isFetchingData}
            />
            <BaseChallengeStatsCard
              tooltipText="Trades completed/Required in challenge"
              title="No. of Trades in challenge"
              progress={tradesCompletedProgressNumber}
              maxValue={expectedTradesCompleted}
              currentValue={traderChallenge}
              loading={isFetchingData}
            />
            <BaseChallengeStatsCard
              tooltipText="Current PnL Vs Target"
              title="PnL"
              progress={pnlProgressNumber}
              specialDataFormat={
                <>
                  <span className={Number(pnlPercent) > 0 ? "text-green" : "text-red"}>
                    {Number(pnlPercent) > 0 && "+"}
                    {pnlPercent}%
                  </span>{" "}
                  vs <span className="text-green">+{expectedPnL}%</span>
                </>
              }
              loading={isFetchingData}
            />
            <BaseChallengeStatsCard
              tooltipText="Active trading days Vs Target"
              title="Days Active"
              progress={activeDaysProgressNumber}
              maxValue={expectedActiveDays}
              currentValue={activeDays}
              loading={isFetchingData}
            />
            <BaseChallengeStatsCard
              tooltipText="Percentage of net positive trades"
              title="WinRate"
              specialDataFormat={
                <>
                  <span className={tradesWinRate >= targetWinRate ? "text-green" : "text-red"}>
                    {Number(tradesWinRate.toFixed(2))}%
                  </span>{" "}
                  vs <span className="text-green">{targetWinRate}%</span>
                </>
              }
              progress={winRateProgressNumber}
              loading={isFetchingData}
            />
            <ul className="grid grid-cols-2 gap-[1rem]">
              <BaseChallengeStatsCard
                tooltipText="Current collateral Vs Target collateral for promotion"
                title="Collateral"
                specialDataFormat={`$${formatBigAmount(balance, USDC_DECIMALS, "0", {
                  mantissa: 0,
                })}/$${expectedCollateral}`}
                loading={isFetchingData}
              />
              <BaseChallengeStatsCard
                tooltipText={currentFundedLevel === "Qualification" ? "Drawdown limit applicable once FUNDED" : "Drawdown is the total +/- of your total PnL of your FUNDED challenge, at -30% your challenge will be failed."}
                title="Drawdown"
                specialDataFormat={drawdownData ? `${drawdownData}%` : "N/A"}
                loading={isFetchingData}
              />
            </ul>
          </ul>
          <div className="Challenge-timer Common-card px-[1.5rem] py-[2rem]">{handleRenderChallengeActionCard()}</div>
        </div>

        <div className="mt-[3.2rem]">
          <h2 className="Common-subtitle">
            <Trans>My Achievements - Coming soon!</Trans>
          </h2>
          <p className="text-inactive mt-[1rem]">
            <Trans>Level up with achievements and turbocharge your points on the ladder.</Trans>
          </p>

          <table className="w-full z-10">
            <thead className="w-full flex mt-[2rem] bg-background-5-v2-only py-[1rem] px-[1rem] border border-border rounded-tl-[2rem] rounded-tr-[2rem] text-[1.3rem] text-inactive font-regular">
              <tr className="w-full flex ">
                <th className="min-w-[8rem] md:min-w-[10.61rem] text-start pr-[0.5rem]">
                  <Trans>Trophy</Trans>
                </th>
                <th className="w-full text-start pr-[0.5rem]">
                  <Trans>Points</Trans>
                </th>
                <th className="w-full text-start pr-[0.5rem]">
                  <Trans>Quantity</Trans>
                </th>
                <th className="w-full text-start pr-[0.5rem]">
                  <Trans>Total Points</Trans>
                </th>
                <th className="w-full text-start pr-[0.5rem]">
                  <Trans>Share</Trans>
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col">
              {achievementsData.length ? (
                achievementsData.map(({ trophy, points, quantity, totalPoints, url, twitterLink }, index) => (
                  <tr
                    key={index}
                    className="w-full flex text-[1.3rem] text-textColor h-[6rem] items-center px-[1rem] border-b border-border"
                  >
                    <td className="min-w-[8rem] md:min-w-[10.61rem] text-start pr-[0.5rem]">
                      <p>
                        <Trans>{trophy}</Trans>
                      </p>
                    </td>
                    <td className="w-full text-start  pr-[0.5rem]">{points}</td>

                    <td className="w-full text-start  pr-[0.5rem]">{quantity}</td>
                    <td className="w-full text-start  pr-[0.5rem]">{totalPoints}</td>
                    <td className="w-full text-start  pr-[0.5rem] flex gap-[1rem]">
                      <Button
                        variant="minor"
                        onClick={() => handleCopy(url)}
                        className="shrink-0 z-30"
                        disabled={clipboard.isCopied && copiedUrl === url}
                      >
                        <div className="relative">
                          <span className={cx({ invisible: clipboard.isCopied && copiedUrl === url })}>
                            {isMobile ? <Trans>Copy</Trans> : <Trans>Copy URL</Trans>}
                          </span>

                          {clipboard.isCopied && copiedUrl === url && (
                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-2 items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                                <path
                                  d="M 13.363 3.322 C 13.49 3.419 13.573 3.562 13.595 3.719 C 13.616 3.877 13.574 4.037 13.478 4.164 L 7.078 12.564 C 6.973 12.702 6.813 12.787 6.64 12.799 C 6.468 12.81 6.298 12.747 6.176 12.624 L 2.576 9.024 C 2.356 8.788 2.362 8.419 2.591 8.191 C 2.819 7.962 3.188 7.956 3.424 8.176 L 6.539 11.29 L 12.523 3.437 C 12.724 3.174 13.1 3.122 13.363 3.322 Z"
                                  fill="rgb(108, 114, 132)"
                                ></path>
                              </svg>

                              <Trans>Copied</Trans>
                            </div>
                          )}
                        </div>
                      </Button>

                      {!isMobileNarrower && (
                        <Button variant="minor" to={twitterLink} newTab className="flex gap-2 shrink-0 z-30">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                            <path
                              d="M 12 2.279 C 11.551 2.478 11.074 2.608 10.586 2.666 C 11.1 2.359 11.485 1.874 11.668 1.304 C 11.185 1.591 10.656 1.793 10.105 1.901 C 9.64 1.405 8.989 1.123 8.308 1.124 C 6.718 1.124 5.551 2.607 5.91 4.147 C 3.93 4.047 2.086 3.113 0.836 1.575 C 0.182 2.699 0.516 4.138 1.597 4.862 C 1.206 4.849 0.824 4.744 0.483 4.554 C 0.456 5.694 1.273 6.761 2.457 6.999 C 2.094 7.097 1.714 7.111 1.345 7.041 C 1.667 8.043 2.592 8.73 3.645 8.75 C 2.613 9.559 1.302 9.926 0 9.77 C 1.126 10.493 2.436 10.877 3.774 10.876 C 8.345 10.876 10.928 7.016 10.771 3.553 C 11.253 3.204 11.669 2.773 12 2.279 Z"
                              fill="#27a2f8"
                            ></path>
                          </svg>

                          <span>
                            <Trans>Tweet</Trans>
                          </span>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="m-auto mt-[2rem]">You don't have any achievement</div>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isResetChallengeModalOpen && (
        <ModalWithPortal
          className="tailwind"
          isVisible={isResetChallengeModalOpen}
          setIsVisible={setIsResetChallengeModalOpen}
          label={t`Reset Challenge`}
        >
          <div className="text-inactive text-[1.4rem] text-center mb-[1.5rem]">
            <Trans>
              Warning: This will reset your challenge, any funding will be lost. Qualification funds will be retained in
              the new challenge
            </Trans>
          </div>
          <Button variant="red" onClick={handleChallengeReset} disabled={isChallengeResetting}>
            <Trans>Reset Challenge</Trans>
          </Button>
        </ModalWithPortal>
      )}

      {isFundAccountModalOpen && (
        <FundAccountModal
          open={isFundAccountModalOpen}
          setOpen={setIsFundAccountModalOpen}
          label="Fund Account"
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          currentCollateralAmount={balance}
          traderContractAddress={traderContractAddress}
          refetchChallengeStats={() => {
            setChallengeResult(undefined);
            refetchChallengeStats();
            getTraderContractData();
          }}
        />
      )}
      {isCashoutModalOpen && (
        <CashoutModal
          cashout={totalCashout}
          isLoading={isFetchingData}
          open={isCashoutModalOpen}
          closeModal={() => setIsCashoutModalOpen(false)}
          traderContract={traderContract!}
          fundedBalance={balance!}
          refetchChallengeStats={() => {
            refetchChallengeStats();
            getTraderContractData();
          }}
        />
      )}
      {isRolloverModalOpen && (
        <RolloverModal
          cashout={totalCashout}
          isLoading={isFetchingData}
          open={isRolloverModalOpen}
          traderContract={traderContract!}
          refetchChallengeStats={() => {
            refetchChallengeStats();
            getTraderContractData();
          }}
          closeModal={() => setIsRolloverModalOpen(false)}
        />
      )}
      {isRestartModalOpen && (
        <RestartModal
          fundedLevel={FundedLevels[resChallengeNumber!.toNumber()]}
          open={isRestartModalOpen}
          cashout={totalCashout}
          closeModal={() => setIsRestartModalOpen(false)}
          refetchChallengeStats={() => {
            refetchChallengeStats();
            getTraderContractData();
          }}
          traderContract={traderContract!}
        />
      )}
      {isErrorModalOpen && (
        <ModalWithPortal
          className="tailwind"
          isVisible={isErrorModalOpen}
          setIsVisible={setIsErrorModalOpen}
          label={t`Close Trades`}
        >
          <div className="text-inactive text-[1.4rem] text-center mb-[1.5rem]">
            <Trans>Challenge ended but you have open trades, close manually before check challenge status.</Trans>
          </div>
          <NavLink
            to={"/"}
            className="inline-block text-center !text-black px-[2rem] py-[1rem] rounded-[1.5rem] w-full mt-[1rem] Create-funded-btn !border-none"
          >
            <Trans>Trade</Trans>
          </NavLink>
        </ModalWithPortal>
      )}
    </>
  );
};

interface BaseChallengeStatsCardProps {
  tooltipText: string;
  title: string;
  progress?: number;
  currentValue?: number;
  maxValue?: number;
  specialDataFormat?: React.ReactNode;
  loading: boolean;
}

const BaseChallengeStatsCard = (props: BaseChallengeStatsCardProps) => {
  const { tooltipText, title, progress, maxValue, currentValue, specialDataFormat, loading } = props;
  const cardData = specialDataFormat
    ? specialDataFormat
    : maxValue && maxValue >= 0
    ? `${currentValue}/${maxValue}`
    : "0/0";

  const handleRenderProgress = () => {
    if (loading) {
      return <div className="skeleton w-[4.5rem] h-[4.5rem] rounded-full"></div>;
    }

    if (progress && progress >= 100) {
      return (
        <div className="w-[4.5rem] h-[4.5rem] rounded-full flex items-center justify-center bg-green-opacity">
          <img src={SuccessTip} alt="Full completion" width="24" height="24" className="stroke-green" />
        </div>
      );
    }

    if (typeof progress !== "undefined") {
      return (
        <div className="w-[4.5rem] h-[4.5rem]">
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            className="circular-progress"
            style={{ "--progress": progress } as React.CSSProperties}
          >
            <circle className="bg"></circle>
            <circle className="fg"></circle>
          </svg>
        </div>
      );
    }

    return null;
  };

  return (
    <li className="flex justify-between items-center Common-card px-[1.5rem] py-[2rem]">
      <div>
        <Tooltip
          renderContent={() => {
            return <div className="Stake-card-infobox-tooltip">{tooltipText}</div>;
          }}
        >
          <div className="Tooltip-handle-wrapper">
            <div className="inactive-text">{title}</div>
            <HiQuestionMarkCircle className="inactive-text" />
          </div>
        </Tooltip>
        <p className="text-white text-[2rem] mt-[0.4rem]">
          {loading ? <div className="skeleton w-[8rem] h-[1.925rem] rounded"></div> : cardData}
        </p>
      </div>
      {handleRenderProgress()}
    </li>
  );
};
