import { useState, useEffect } from "react";
import { useFundedTrader } from "./useFundedTrader";

type ChallengeT = {
  TraderContractAddress: string;
  LiveBalance: number;
  StartingCapital: number;
  DrawdownPercent: number;
  Timestamp: string;
};

export function useDrawdown(): string | null {
  const [drawdown, setDrawdown] = useState<string | null>(null);
  const { proxyTraderContractAddress } = useFundedTrader();

  useEffect(() => {
    if (!proxyTraderContractAddress) return;

    const fetchDrawdown = async () => {
      try {
        const response = await fetch(
          `https://kitsune.foxify.trade/api/funded/challenges/${proxyTraderContractAddress}`
        );
        const stats = (await response.json()) as ChallengeT;
        if (stats && stats.DrawdownPercent !== undefined) {
          setDrawdown(stats.DrawdownPercent.toFixed(3));
        }
      } catch (error) {
        // console.error("Error fetching drawdown data", error);
      }
    };

    // Fetch immediately and then every 5 seconds.
    fetchDrawdown();
    const interval = setInterval(fetchDrawdown, 5000);
    return () => clearInterval(interval);
  }, [proxyTraderContractAddress]);

  return drawdown;
}
