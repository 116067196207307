import React, { useCallback, useEffect, useMemo, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { approveTokens, useInfoTokens } from "domain/tokens";
import { getTokenInfo } from "domain/tokens/utils";
import "./StakeV3.scss";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import "./Stake.css";
import { getContractAddress } from "config/contracts";
import Token from "abis/common/Token.json";
import { useChainId } from "lib/chains";
import { getChainName, IS_NETWORK_DISABLED } from "config/chains";
import { bigNumberify, expandDecimals, formatAmount, parseValue } from "lib/numbers";
import { callContract, contractFetcher } from "lib/contracts";
import { DEFAULT_SLIPPAGE_AMOUNT, PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { FOX_DECIMALS } from "lib/constants";
import FoxToken from "img/Fox_token.png";
import EbFox from "abis/common/EbFox.json";
import FoxifyMaxi from "abis/common/FoxifyMaxi.json";
import { useLocalStorageSerializeKey } from "../../lib/localStorage";
import { SLIPPAGE_BPS_KEY } from "../../config/localStorage";
import { useNFT } from "../../hooks/useNFT";

export const STAKE_ACTION = "STAKE_ACTION";
export const BUY_STAKE_ACTION = "BUY_STAKE_ACTION";
export const UNSTAKE_ACTION = "UNSTAKE_ACTION";
export const UNSTAKE_NFT_ACTION = "UNSTAKE_NFT_ACTION";
export const FOX_TOKEN = "FOX_TOKEN";
export const EBFOX_TOKEN = "EBFOX_TOKEN";

const fractions = [
  {
    value: 0.1,
    label: "10%",
  },
  {
    value: 0.25,
    label: "25%",
  },
  {
    value: 0.5,
    label: "50%",
  },
  {
    value: 0.75,
    label: "75%",
  },
  {
    value: 1,
    label: "100%",
  },
];

const StakeModal = ({
  open,
  setOpen,
  foxBalance,
  foxStaked,
  stakeModalData,
  connectWallet,
  setPendingTxns,
  foxPrice,
  ethPrice,
  entryFee,
  exitFee,
}) => {
  const [hideModal] = useState(() => JSON.parse(localStorage.getItem("doNotShowModalForBuyingPLP")) || false);
  const [foxValue, setFoxValue] = useState(0);
  const [fraction, setFraction] = useState();
  const [annualRoi] = useState(0);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ethBalance, setEthBalance] = useState(0);
  const { provider, isActive, account } = useWeb3React();
  const { chainId } = useChainId();
  const { arrayOfTokens } = useNFT();

  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);

  const { action, token } = stakeModalData;
  const isStaking = action === STAKE_ACTION;
  const isBuyStaking = action === BUY_STAKE_ACTION;
  const isUnstaking = action === UNSTAKE_ACTION;
  const isUnstakingNFT = action === UNSTAKE_NFT_ACTION;

  const tokenName = token === EBFOX_TOKEN && !isBuyStaking ? "ebFOX" : isBuyStaking ? "ETH" : "FOX";

  const actionName = (action) => {
    switch (action) {
      case STAKE_ACTION:
        return t`Stake`;
      case BUY_STAKE_ACTION:
        return t`Buy & Stake ETH`;
      default:
        return t`Unstake`;
    }
  };

  const getBalance = useCallback(async () => {
    return provider?.getBalance(account);
  }, [account, provider]);

  useEffect(() => {
    getBalance().then((balance) => {
      setEthBalance(balance);
    });
  }, [account, getBalance]);

  const modalLabel = isBuyStaking ? actionName(action) : `${actionName(action)} ${tokenName}`;

  const balanceLabel = isStaking || isBuyStaking ? t`Balance:` : t`FOX Staked Balance:`;
  const balanceFormatted =
    isStaking && foxBalance && foxStaked
      ? formatAmount(foxBalance, FOX_DECIMALS, 2, false)
      : isBuyStaking && ethBalance
      ? formatAmount(ethBalance, FOX_DECIMALS, 5, false)
      : formatAmount(foxStaked, FOX_DECIMALS, 2, false);

  const inputAmount = foxValue ? parseValue(foxValue, FOX_DECIMALS) : bigNumberify(0);

  const foxPriceBigNum = BigNumber.from(String(Math.floor(foxPrice * 10 ** 18)));
  const ethPriceBigNum = ethPrice ? ethers.utils.parseEther(Number(ethPrice).toFixed(4)) : BigNumber.from(0);

  const foxAmountInUsd = useMemo(
    () => (foxPrice && inputAmount ? foxPriceBigNum?.mul(inputAmount)?.div(expandDecimals(1, 18)) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [foxPrice, inputAmount]
  );
  const ethAmountInUsd = useMemo(
    () => (ethPrice && inputAmount ? ethPriceBigNum?.mul(inputAmount)?.div(expandDecimals(1, 18)) : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ethPrice, inputAmount]
  );

  const amountInUsd = isBuyStaking
    ? ethAmountInUsd && formatAmount(ethAmountInUsd, 18, 2)
    : foxAmountInUsd && formatAmount(foxAmountInUsd, FOX_DECIMALS, 2);

  const foxTokenAddress = getContractAddress(chainId, "Fox");
  const ebFoxAddress = getContractAddress(chainId, "EbFox");
  const foxifyMaxiAddress = getContractAddress(chainId, "FoxifyMaxi");

  const contractAddressForTheTokenAllowance = token === FOX_TOKEN ? foxifyMaxiAddress : ebFoxAddress;
  const { data: tokenAllowance, mutate: refetchTokenAllowance } = useSWR(
    [
      isActive,
      chainId,
      foxTokenAddress,
      "allowance",
      account || PLACEHOLDER_ACCOUNT,
      contractAddressForTheTokenAllowance,
    ],
    contractFetcher(provider, Token),
    {
      refreshInterval: 3000,
    }
  );

  // We don't need token allowance check, if modal is in Unstake mode
  const needApproval = isStaking && tokenAllowance && inputAmount && inputAmount.gt(tokenAllowance);

  const insufficientBuyStakeBalance = isBuyStaking && ethBalance && inputAmount && inputAmount.gt(ethBalance);
  const insufficientStakeBalance = isStaking && foxBalance && inputAmount && inputAmount.gt(foxBalance);
  const insufficientUnstakeBalance = (isUnstaking || isUnstakingNFT) && foxStaked && inputAmount && inputAmount.gt(foxStaked);
  const isInputAmountEmpty = !inputAmount || inputAmount.eq(0)

  const getError = () => {
    if (IS_NETWORK_DISABLED[chainId]) {
      if (isStaking) return [t`${tokenName} stake disabled, pending ${getChainName(chainId)} upgrade`];
      return [t`${tokenName} unstake disabled, pending ${getChainName(chainId)} upgrade`];
    }

    if (isInputAmountEmpty) {
      return [t`Enter an amount`];
    }

    if ((isUnstaking || isUnstakingNFT) && !insufficientUnstakeBalance) {
      return [t`Unstake ${tokenName}`];
    }

    if ((isUnstaking || isUnstakingNFT) && insufficientUnstakeBalance) {
      return [t`Insufficient staked balance`];
    }

    if (insufficientBuyStakeBalance) {
      return [t`Insufficient ${tokenName} balance`];
    }

    if (isStaking && insufficientStakeBalance) {
      return [t`Insufficient ${tokenName} balance`];
    }

    return [false];
  };

  useEffect(() => {
    if (fraction) {
      const toFixedDecimals = isBuyStaking ? 5 : 3;
      const formatedFoxValue = parseFloat((Number(balanceFormatted) * fraction)?.toFixed(toFixedDecimals));
      setFoxValue(formatedFoxValue);
    }
    return () => setFraction(undefined);
  }, [balanceFormatted, fraction, isBuyStaking]);

  const { infoTokens } = useInfoTokens();

  const approveToken = () => {
    const spenderAddress = token === FOX_TOKEN ? foxifyMaxiAddress : ebFoxAddress;
    approveTokens({
      setIsApproving: setIsWaitingForApproval,
      library: provider,
      tokenAddress: foxTokenAddress,
      spender: spenderAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsApproving(true);
      },
      setPendingTxns,
      infoTokens,
      getTokenInfo,
      txnSuccessCallback: async () => {
        await refetchTokenAllowance();
        setIsApproving(false);
      },
    });
  };

  useEffect(() => {
    if (hideModal) {
      localStorage.setItem("doNotShowModalForBuyingPLP", JSON.stringify(hideModal));
    } else {
      localStorage.setItem("doNotShowModalForBuyingPLP", JSON.stringify(hideModal));
    }
  }, [hideModal]);

  const closeModal = () => {
    setFoxValue("");
    setOpen(!open);
  };

  const stakeFox = () => {
    setIsSubmitting(true);

    const contract =
      token === EBFOX_TOKEN
        ? new ethers.Contract(ebFoxAddress, EbFox.abi, provider.getSigner())
        : new ethers.Contract(foxifyMaxiAddress, FoxifyMaxi.abi, provider.getSigner());

    const method = isBuyStaking ? "depositWithNative" : "deposit";

    const SLIPPAGE_CONSTANT = foxValue * (savedSlippageAmount / 1000); //used to address possible price impact as minOut does decrease based on price impact

    // its EthValue for buy and stake operation
    const minOut = inputAmount.add(ethers.utils.parseEther(SLIPPAGE_CONSTANT.toString()));

    const ethParam = minOut;
    const params = [isBuyStaking ? ethParam : inputAmount];

    const failMsg = isBuyStaking ? "Buy and stake failed." : "Stake Failed.";

    callContract(chainId, contract, method, params, {
      sentMsg: t`Stake submitted.`,
      failMsg: t`${failMsg}`,
      successMsg: `${foxValue} ` + t`${tokenName} staked`,
      setPendingTxns,
      txnSuccessCallback: () => {
        setIsSubmitting(false);
        closeModal();
      },
      ...(isBuyStaking && { value: inputAmount }),
    }).catch(() => {
      setIsSubmitting(false);
    });
  };

  const unstakeFox = () => {
    setIsSubmitting(true);

    const contract =
      token === EBFOX_TOKEN
        ? new ethers.Contract(ebFoxAddress, EbFox.abi, provider.getSigner())
        : new ethers.Contract(foxifyMaxiAddress, FoxifyMaxi.abi, provider.getSigner());
    const method = isUnstakingNFT ? "withdrawNFT" : "withdraw";
    const params = isUnstakingNFT ? [arrayOfTokens, inputAmount] : [inputAmount];

    callContract(chainId, contract, method, params, {
      sentMsg: t`Unstake submitted.`,
      failMsg: t`Unstake failed.`,
      successMsg: `${foxValue} ` + t`${tokenName} unstaked`,
      setPendingTxns,
      gasPreference: "aggressive",
      txnSuccessCallback: () => {
        setIsSubmitting(false);
        setFoxValue("");
      },
      arrayOfTokens,
    }).catch(() => {
      setIsSubmitting(false);
    });
  };

  const onClickPrimary = () => {
    if (!isActive) {
      connectWallet();
      return;
    }

    if (needApproval) {
      approveToken();
      return;
    }

    if (isStaking || isBuyStaking) {
      stakeFox();
    } else {
      unstakeFox();
    }
  };

  const getPrimaryText = () => {
    if (!isActive) {
      return t`Connect Wallet`;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return error;
    }

    if (needApproval && isWaitingForApproval) {
      return t`Waiting for Approval`;
    }
    if (isApproving) {
      return t`Approving ${tokenName}...`;
    }
    if (needApproval) {
      return t`Approve ${tokenName}`;
    }

    if (isSubmitting) {
      return isStaking ? t`Stake...` : isBuyStaking ? t`Stake...` : t`Unstake...`;
    }

    return actionName(action);
  };

  const isPrimaryBtnDisabled = () =>
    isWaitingForApproval ||
    isApproving ||
    isSubmitting ||
    insufficientBuyStakeBalance ||
    insufficientStakeBalance ||
    insufficientUnstakeBalance ||
    isInputAmountEmpty;

  return (
    <Modal className="Connect-wallet-modal tailwind" isVisible={open} setIsVisible={closeModal} label={modalLabel}>
      <div className="Earn-modal">
        <div className="Stake-modal-top-section">
          <div className="inactive-text">Amount</div>
          <div className="input-wrapper">
            <input
              type={"number"}
              className="Earn-input"
              value={foxValue}
              onChange={(e) => {
                setFraction(undefined);
                setFoxValue(e.target.value);
              }}
            />
            <div className="input-end-content">
              <div className="inactive-text">~ ${amountInUsd}</div>
            </div>
          </div>

          <div className="inactive-text">
            {balanceLabel} {balanceFormatted} {isBuyStaking ? "ETH" : "FOX"}
          </div>
          <div className="fraction-selector-wrapper">
            {fractions.map((fraction, i) => (
              <div className="fraction-selector-item" key={i} onClick={() => setFraction(fraction.value)}>
                {fraction.label}
              </div>
            ))}
          </div>
        </div>
        {token === FOX_TOKEN ? (
          <div className="Stake-modal-bottom-section">
            {isStaking && (
              <>
                <div className="label-value">
                  <div className="inactive-text">
                    <Trans>Estimated annual ROI</Trans>
                  </div>
                  <div className="inactive-text">{annualRoi} USDC</div>
                </div>
                <a className="Modal-info-card" rel="noopener noreferrer" href="https://arbidex.fi/swap">
                  <img src={FoxToken} alt="token" className="Token-icon" />
                  <div className="info-block popup-infoblock">
                    <div className="white-text">
                      <Trans>Get tokens on ArbiDex</Trans>
                    </div>
                    <div className="inactive-text">{tokenName}</div>
                  </div>
                  <HiOutlineArrowSmRight className="right-arrow" />
                </a>
              </>
            )}
            <Button
              variant="primary-action"
              disabled={isPrimaryBtnDisabled()}
              onClick={onClickPrimary}
              className="w-full rounded mt-1"
            >
              {getPrimaryText()}
            </Button>
          </div>
        ) : (
          <div className="Stake-modal-bottom-section">
            {isStaking || isBuyStaking ? (
              <>
                <div className="label-value">
                  <div className="inactive-text">
                    <Trans>Stake Fee</Trans>
                  </div>
                  <div className="inactive-text">{entryFee ? formatAmount(entryFee, 1, 1, false) : "0.00"}%</div>
                </div>
                <div className="label-value">
                  <div className="inactive-text">
                    <Trans>Estimated annual ROI</Trans>
                  </div>
                  <div className="inactive-text">{annualRoi} USDC</div>
                </div>
              </>
            ) : (
              <div className="label-value">
                <div className="inactive-text">
                  <Trans>Unstake Fee</Trans>
                </div>
                <div className="inactive-text">{exitFee ? formatAmount(exitFee, 1, 1, false) : "0.00"}%</div>
              </div>
            )}

            <Button
              variant="primary-action"
              disabled={isPrimaryBtnDisabled()}
              onClick={onClickPrimary}
              className="w-full rounded mt-1"
            >
              {getPrimaryText()}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StakeModal;
