import React, { useState, useEffect, useCallback } from "react";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import Button from "components/Button/Button";
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useConnectWalletUi } from "lib/useConnectWalletUi";
import { BigNumber, ethers } from "ethers";
import VaultConfig from "abis/common/KitsuneVault.json";
// import ControllerConfig from "abis/common/KitsuneController.json";

export interface ActionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionType: "Deposit" | "Request Withdrawal";
  onSubmit: (inputValues: { amount?: string }) => void;
  label: string;
}

const ActionModal: React.FC<ActionModalProps> = ({ open, setOpen, actionType, onSubmit, label }) => {
  const [amount, setAmount] = useState<string>("");
  const [balance, setBalance] = useState<string>("0");
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const { isActive, account, provider } = useWeb3React();
  const { connectWallet } = useConnectWalletUi();

  const vaultAddress = VaultConfig.address;
  // const controllerAddress = ControllerConfig.address;

  const fetchBalance = useCallback(async () => {
    if (!provider || !account) return;
    setIsFetching(true);
    try {
      const vaultContract = new ethers.Contract(vaultAddress, VaultConfig.abi, provider.getSigner());
      // const controllerContract = new ethers.Contract(controllerAddress, ControllerConfig.abi, provider.getSigner());
      // Fetch the USDC token address from the vault
      const usdcAddress = await vaultContract.asset();
      // Assuming the USDC ABI is the same as used in VaultConfig; if not, use the correct token ABI.
      const usdcContract = new ethers.Contract(usdcAddress, VaultConfig.abi, provider.getSigner());
      const usdcBalance = await usdcContract.balanceOf(account);
      setBalance(ethers.utils.formatUnits(usdcBalance, 6));

      if (actionType === "Deposit") {
        // Fetch user's USDC balance
        const usdcContract = new ethers.Contract(await vaultContract.asset(), VaultConfig.abi, provider.getSigner());
        const usdcBalance = await usdcContract.balanceOf(account);
        setBalance(ethers.utils.formatUnits(usdcBalance, 6));
      } 
      else if (actionType === "Request Withdrawal") {
        const maxRedeemBN: BigNumber = await vaultContract.maxRedeem(account);
        setBalance(ethers.utils.formatUnits(maxRedeemBN, 18));
      } 
    } catch (error) {
      setBalance("0");
    } finally {
      setIsFetching(false);
    }
  }, [provider, account, actionType, vaultAddress]);

  useEffect(() => {
    if (isActive) {
      fetchBalance();
    }
  }, [isActive, actionType, fetchBalance]);

  const handleClose = () => {
    setOpen(false);
    setAmount("");
  };

  const handleSubmit = () => {
    if (parseFloat(amount) > parseFloat(balance) || parseFloat(amount) <= 0) return;
    onSubmit({ amount });
    handleClose();
  };

  const handleMax = () => {
    setAmount(balance);
  };

  const isSubmitDisabled = !amount || parseFloat(amount) <= 0 || parseFloat(amount) > parseFloat(balance);

  return (
    <ModalWithPortal className="tailwind" isVisible={open} setIsVisible={handleClose} label={label}>
      {isActive ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-[1rem] w-full">
            <div className="flex justify-between items-center">
              <span className="text-[1.4rem]">{actionType === "Deposit" ? "USDC Balance:" : "Available KIT:"}</span>
              <span className="text-[1.4rem]">
              {isFetching ? "Loading..." : `${Number(balance).toLocaleString()} ${actionType === "Deposit" ? "USDC" : "KIT"}`}
              </span>
            </div>
            <div className="text-[1.4rem]">{actionType} Amount</div>
            <div className="flex items-center gap-[0.5rem]">
              <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full px-[1rem] py-[0.8rem] border border-border rounded-md"
              />
              <button onClick={handleMax} className="px-[1rem] py-[0.8rem] bg-main rounded-md">
                Max
              </button>
            </div>
          </div>
          <Button
            variant="primary-action"
            onClick={handleSubmit}
            className={`w-full mt-[1.5rem] rounded-md ${isSubmitDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={isSubmitDisabled}
          >
            <Trans>Submit</Trans>
          </Button>
        </div>
      ) : (
        <Button
          variant="primary-action"
          className="w-full"
          onClick={() => {
            connectWallet();
            handleClose();
          }}
        >
          <Trans>Connect Wallet</Trans>
        </Button>
      )}
    </ModalWithPortal>
  );
};

export default ActionModal;
